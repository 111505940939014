import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from './routes';

const WithMeta = (props) => {

  useEffect(() => {
    if (props.meta?.title) {
      document.title = props.meta.title;
    }
  }, [props.meta]);
  return props.children;
};

const renderRoutes = (routes) => {
  return routes.map((route) => {
    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <WithMeta meta={route.meta}>
            <route.component />
          </WithMeta>
        }
      >
        {route.children?.map((subRoute) => {
          return (
            <Route
              key={subRoute.path}
              path={subRoute.path}
              element={
                <WithMeta meta={subRoute.meta}>
                  <subRoute.component />
                </WithMeta>
              }
            />
          );
        })}
      </Route>
    );
  });
};

const AppRouter = () => {
  return (
    <Router>
      <Suspense fallback={<>...</>}>
        <Routes>{renderRoutes(routes)}</Routes>
      </Suspense>
    </Router>
  );
};

export default AppRouter;