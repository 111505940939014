/* eslint-disable jsx-a11y/alt-text */
import React ,{useEffect,useState}from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout, Menu, Space, Popover, Button, Avatar } from 'antd';
import {
  HomeOutlined,
  FileSearchOutlined,
  ShoppingOutlined,
  UserOutlined,
  PropertySafetyOutlined,
  LogoutOutlined,
  DownOutlined,
  SendOutlined,
  ApartmentOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from "@ant-design/icons";
import "./index.css";
import { post } from "../../src/http/index";
import Logo from '../logo.png';


const { Header, Sider, Content } = Layout;

const LayoutBox = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [demenu, setDemenu] = useState(window.location.pathname);


  const content = (
    <Space direction="vertical" size="middle">
      <Button type="text" onClick={()=>goToMyCenter()} icon={<UserOutlined />}>
        个人中心
      </Button>
      <Button type="text" onClick={()=>handleLogout()} icon={<LogoutOutlined />}>
        退出登录
      </Button>
    </Space>
  );
  const goToMyCenter = ()=>{
    navigate('/my-center');
  }

  const handleLogout = () => {
    navigate('/');
    localStorage.clear()
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("首页", "/home", <HomeOutlined style={{ color: "#1890ff" }} />),
    getItem(
      <span style={{fontSize: '12px', paddingLeft: '8px'}}>订阅</span>,
      "g1",
      null,
      [
        getItem(
          "我的节点",
          "/my-node",
          <ApartmentOutlined style={{ color: "#1890ff" }} />
        ),
        getItem(
          "购买节点",
          "/buy-node",
          <ShoppingOutlined style={{ color: "#1890ff" }} />
        ),
      ],
      "group"
    ),
    getItem(
      <span style={{fontSize: '12px', paddingLeft: '8px'}}>财务</span>,
      "g2",
      null,
      [
        getItem(
          "我的订单",
          "/my-order",
          <FileSearchOutlined style={{ color: "#1890ff" }} />
        ),
        getItem(
          "我的充值",
          "/my-recharge",
          <PropertySafetyOutlined style={{ color: "#1890ff" }} />
        ),
      ],
      "group"
    ),
    getItem(
      <span style={{fontSize: '12px', paddingLeft: '8px'}}>用户</span>,
      "g3",
      null,
      [
        getItem(
          "个人中心",
          "/my-center",
          <UserOutlined style={{ color: "#1890ff" }} />
        ),
        getItem(
          "推广返利",
          "/my-spread",
          <SendOutlined style={{ color: "#1890ff" }} />
        ),
      ],
      "group"
    ),
  ];

  const onClick = (e) => {
    navigate(e.key);
    setDemenu(e.key);
  };

  const fetchData = async () => {
    const { data } = await post('api/account/my_profile');
    setUserName(data.data.username);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <Layout>
        <Header
          style={{
            background: '#0052d9',
            padding: '0 25px 0 0',
            minWidth: '1028px',
          }}>
          <div className='header-box'>
            <div className='logo'>
              <img
                src={Logo}
                width='35px'
              />
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  paddingLeft: '10px',
                }}>
                老鱼IP
              </span>
            </div>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
            <Popover placement='bottom' content={content} trigger='hover'>
              <div className='user'>
                <Avatar
                  style={{
                    backgroundColor: '#fff',
                    marginRight: '10px'
                  }}
                  icon={<UserOutlined style={{color: '#333'}} />}
                /> {userName}
                <DownOutlined style={{ paddingLeft: '10px' }} />
              </div>
            </Popover>
          </div>
        </Header>
        <Layout className='main'>
          <Sider className='menu-style' trigger={null} collapsible collapsed={collapsed}>
            <Menu
              style={{ borderRight: '0px' }}
              defaultSelectedKeys={[demenu]}
              mode='inline'
              onClick={onClick}
              items={items}
            />
          </Sider>
          <Content
            style={{
              minWidth: '798px',
              padding: '15px',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default LayoutBox;
