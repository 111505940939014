import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate('/home');
    localStorage.setItem(localStorage.setItem("de-menu", '/home'))
  }
  return (
    <Result
      status='404'
      title='404'
      subTitle='对不起，没有找到此页面。请返回首页！'
      extra={<Button type='primary' onClick={goHome}>返回首页</Button>}
    />
  );
};

export default NotFoundPage;
