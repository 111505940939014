var storage = {
    key: 'user_token',
    loginUserToken: '',
    setItem(key, value) {
        if(!key) {
            key = this.key
        }
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem(key, JSON.stringify(value))
        }
    },
    getItem(key) {
        if(!key) {
            key = this.key
        }
        if (typeof localStorage !== 'undefined') {
            this.loginUserToken = JSON.parse(localStorage.getItem(key))
        }
        return this.loginUserToken;
    },
    removeItem(key) {
        if(!key) {
            key = this.key
        }
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem(key)
        }
    },
}

export default storage;