import React from "react";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import "./App.css";
import AppRouter from "./router";

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <AppRouter />
    </ConfigProvider>
  );
}

export default App;
